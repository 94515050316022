<template>
	<div class="read">
		<div class="lyric">
			<Subtitle v-if="subtitlesType !== 4" :showAll="true"/>
			<p class="subtitle-closed" v-else>
				<i class="iconfont icon-danmu-" style="font-size: 22px;"></i>字幕关闭
			</p>
		</div>
		<control-bar page="read" />
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import ControlBar from "@/components/VideoControlBar";
import Subtitle from '@/components/Subtitle';

const store = useStore();
const subtitlesType = computed(() => store.state.subtitles.subtitleType);

</script>

<style lang="scss">
.read {
	background: #0f1013;
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	.lyric {
		margin-left: 26%;
		width: 47vw;
		height: calc(100vh - 120px);
		background: #131418;
		overflow: overlay;
		padding: 28px 12px;
	}
	.subtitle-closed {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		color: #fff;
		margin-top: 50%;
		transform: translateY(-50%);
	}

	::-webkit-scrollbar {
		width: 4px;
		background: #1F1F25;
	}

	::-webkit-scrollbar-thumb {
		background-clip: padding-box;
		border-radius: 4px;
		background-color: #2F2F34;
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: #2F2F34;
	}

	::-webkit-scrollbar-button {
		width: 0;
		height: 0;
		display: none;
	}

	::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}
</style>
