/**
 *  1.axios配置  2.请求拦截器 3.接口报错统一处理
 */

// 引入模块
// import store from '@/store/index' //登录后将token存储在store 中因此需要引入
import qs from 'qs' // 使用qs 将数据格式转为表单提交，看后台需要什么类型的传参，非必须
import axios from 'axios'

// axios初始化：延迟时间，主路由地址,是否允许跨域
let instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : 'https://chat.onechatai.cn',//默认环境
  timeout: 10000,
});

// 设置拦截器
instance.interceptors.request.use(function (config) {
  let token = localStorage.getItem('vb-token')
  let aiVideo = config.url.indexOf("aivideo") > -1;
  if (aiVideo) {
    config.headers["Authorize"] = localStorage.getItem('vb-token')
    return config;
  }
  if(config.url.indexOf("voiceGetUrl") !== -1){
    if(token&&token!=null&&token!=''){
      config.headers = {
        //添加token，unit，busType，processId
            'Content-Type': 'application/x-www-form-urlencoded', //配置请求头
            "Authorize" : token,
            token: 1,
            unit: 1,
            busType: 1,
            processId: 1,
        }
    }else{
      config.headers = {
        //添加token，unit，busType，processId
            'Content-Type': 'application/x-www-form-urlencoded', //配置请求头
            token: 1,
            unit: 1,
            busType: 1,
            processId: 1,
        }
    }
  
  }else{
    if(token&&token!=null&&token!=''){
      config.headers = {
        //添加token，unit，busType，processId
        'Content-Type': 'application/json', //配置请求头
        "Authorize" : token,
        token: 1,
        unit: 1,
        busType: 1,
        processId: 1,
      }
    }else{
      config.headers = {
        //添加token，unit，busType，processId
            'Content-Type': 'application/json', //配置请求头
            token: 1,
            unit: 1,
            busType: 1,
            processId: 1,
        }
    }
  }     
  return config;
}, function (error) {
  console.log("拦截器---err")
  //请求错误时做些事
  return Promise.reject(error);
});
//响应拦截器
instance.interceptors.response.use(function (response) {

  
  //对响应数据做些事
  return response;
}, function (error) {
  //请求错误时做些事
  return Promise.reject(error);
});

// 是否销毁拦截器
// 1.给拦截器起个名称 var myInterceptors = instance.interceptors.requesst.use();
// 2.instance.interceptors.request.eject(myInterceptor);

// 请求成功的回调
function checkStatus(res) {
  //请求结束成功
  if (res.status === 200 || res.status === 304) {
    return res.data
  }
  return {
    code: 0,
    msg: res.data.msg || res.statusText,
    data: res.statusText
  }
}
// 请求失败的回调
function checkCode(res) {
//   if (res.code === 0) {
//     throw new Error(res.msg)
//   }
  return res
}
//模块化导出
export default {
  get(url, params) {
    if (!url) return;
    return instance({
      method: 'get',
      url: url,
      params,
      timeout: 30000
    }).then(checkStatus).then(checkCode)
  },
  post(url, data) {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data: qs.stringify(data),
      timeout: 30000
    }).then(checkStatus).then(checkCode)
  },
  postform(url, data) {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data: data,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      timeout: 30000
    }).then(checkStatus).then(checkCode)
  },
  postjson(url, data) {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data: data,
      timeout: 30000
    }).then(checkStatus).then(checkCode)
  },
  postFile(url, data) {
    if (!url) return;
    return instance({
      method: 'post',
      url: url,
      data
    }).then(checkStatus).then(checkCode)
  }
}