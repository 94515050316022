<template>
	<div class="outdiv" style="">
		<div style="display:flex;    align-items: center;margin-bottom: 10px;">
			<div style="width:28px;    display: flex;">
				<img v-if="audioStatus == 'run'" src="../assets/image/sendend.svg" alt="" @click="playAudio" style="cursor: pointer;">
				<img v-else src="../assets/bofanginfo.svg" alt="" @click="playAudio" style="cursor: pointer;">
			</div>
			
			<div style="margin:0 12px">{{videoStart}}</div>
			<div class="block" style="width:700px">
				<el-slider v-model="value"></el-slider>
			</div>
			<div style="margin:0 12px">{{videoEnd}}</div>
			<audio @timeupdate="updateProgress" controls ref="audioRef" style="display: none" @canplay="canplaysong">
				<source :src="voiceurl" type="audio/mpeg" />您的浏览器不支持音频播放
			</audio>
		

		</div>
		<div style="border-bottom:1px solid rgb(255 255 255 / 10%);width: 100%;"></div>
		<div style="margin-top: 16px;">
			{{content}}
		</div>
	</div>
</template>
  
  <script>
  import { ElMessage } from 'element-plus'
  export default {
	props:{
		file: {},
	},
	data() {
	  return {
			audioStatus: 'stop',
			videoStart: '00:00',
			value: 0,
			duration:0,
			videoEnd: '00:00',
			voiceurl:'',
			content:'',
	  }
	},
	mounted(){
		this.voiceurl =  this.file.voiceUrl
		this.content = this.file.content
	},
	methods:{
		canplaysong(){
            this.duration = parseInt(this.$refs.audioRef.duration)
			this.videoEnd = this.transTime(this.duration)
			// this.duration  = this.$refs.audioRef.duration
		},
		//播放暂停控制
		playAudio () {
			let recordAudio = this.$refs.audioRef; //获取audio元素
			if (recordAudio.paused) {
			
				if(localStorage.getItem("voicestatus") == 1){
					ElMessage({
						showClose: true,
						message: '有录音正在播放',
						type: 'error',
					})
					return
				}
				recordAudio.play();
				this.audioStatus = "run"
				localStorage.setItem("voicestatus",1)
			} else {
				recordAudio.pause();
				this.audioStatus = "stop"
				localStorage.setItem("voicestatus",0)
			}
		},
		updateProgress (e) {
			var value = e.target.currentTime / this.duration
			this.value = value * 100
			if (e.target.currentTime > this.duration) {
				this.audioStatus = 'stop'
				localStorage.setItem("voicestatus",0)
				this.value = 0
				this.videoStart = this.transTime(0)
				return
			}
			this.value = value * 100
			this.videoStart = this.transTime(this.$refs.audioRef.currentTime)
		},
		/**
		 * 音频播放时间换算
		 * @param {number} value - 音频当前播放时间，单位秒
		 */
		transTime (value) {
			var time = "";
			var h = parseInt(value / 3600);
			value %= 3600;
			var m = parseInt(value / 60);
			m = m < 10 ? '0' + m : m
			var s = parseInt(value % 60);
			s = s < 10 ? '0' + s : s
			time = m + ":" + s;
			return time;
		},
		// 进度条
		onChange (val) {
			let recordAudio = this.$refs.audioRef; //获取audio元素
			if (!recordAudio.paused || recordAudio.currentTime != 0) {
				recordAudio.currentTime = recordAudio.duration * val / 100
				this.videoStart = this.transTime(val / 100 * this.duration)
			}
		}
	}
  }
  </script>

<style scoped>
.outdiv{
	width: 852px;
	background-color: #1F232A !important;
    border-color: #2b2f30;
	color: #fff !important;
	letter-spacing: 1px;
    border-width: 4px;
	font-size: 14px;
    position: relative;
    padding: 12px 16px;
    line-height: 26px;
    margin-right: 30px;
    margin-left: 200px;
	border-radius: 16px;

    border: 1px solid #3E4447;
}
::v-deep .el-slider__bar {
	height:1px;
	background-color:#fff
}
::v-deep .el-slider__runway{
	height:1px;
	background-color:#626469
}
::v-deep .el-slider__button{
	height: 8px;
    width: 8px;
	top: -2px;
	position: relative;
	border: solid 2px #fff
}
</style>