import axios from '../utils/request.js'


export default {
  aiVideoList(data) {
    return axios.post('/api/v1/aivideo/getVideoList', data)
  },
  aiVideoType(data) {
    return axios.post('/api/v1/aivideo/getVideoType', data)
  },
  aiVideoInfo(data) {
    return axios.post('/api/v1/aivideo/getVideoinfo', data)
  },
  queryCaptions(url) {
    return axios.get(url);
  },
  collectVideo(id, type) {
    return axios.post('/api/v1/aivideo/collectVideo', { video_id: id, type })
  },
  // 收藏/取消收藏单词接口
  collectWord(params) {
    return axios.post('/api/v1/aivideo/collectWord', params)
  },
  // 掌握/取消掌握单词接口
  masterWord(params) {
    return axios.post('/api/v1/aivideo/masterWord', params)
  },
  // 掌握/取消掌握单词接口
  allMasterWord(params) {
    return axios.post('/api/v1/aivideo/allmasterWord', params)
  },
  collectSentence(data) {
    return axios.postjson('/api/v1/aivideo/collectSentence', data)
  },
  aiVideoWordsList(data) {
    return axios.post('/api/v1/aivideo/getDaoTuWordsList', data)
  },
  sentenceList(data) {
    return axios.post('/api/v1/aivideo/getSentenceList', data)
  },
  // 获取单词解释列表
  getExplainByWordId(data) {
    let method = 'post'
    if (data.word instanceof Array) {
      method = 'postjson'
    }
    return axios[method]('/api/v1/aivideo/getExplainByWordId', data)
  },
  // 通过单词获取单词详情
  getWordsInfo(data) {
    return axios.post('/api/v1/aivideo/getWordsInfo', data)
  },
  // 获取预览图
  getVideoImageById(videoId, second) {
    return axios.postjson('/api/v1/aivideo/getVideoImageById', { videoId, second })
  },
  // 获得单词的全部信息
  getAllByWord(word) {
    return axios.postjson('api/v1/aivideo/getAllByWord', { word })
  },
  // 找出高亮的单词/词组
  getHeightLightWord(word_list) {
    return axios.postjson('api/v1/aivideo/getHeightLightWord', { word_list })
  },
  getHideWord(word_list) {
    return axios.postjson('api/v1/aivideo/getHideWord', { word_list })
  },
  //绑定例句和解释之间的关系
  contentSenAndWord(data) {
    return axios.postjson('api/v1/aivideo/contentSenAndWord', data)
  }
}