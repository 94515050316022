<template>
    <div class="blank">
        <div class="page-title">
            <player :width="335" :height="184" />
            <div class="percent">26/653</div>
            <div class="time">12:33:34</div>
        </div>
        <div class="content">
            <div>
                <div>
                    <ul class="word-group hideText">
                        <li v-for="item in group" :key="item" class="item">{{ item }}</li>
                    </ul>
                </div>
                <div class="fy">{{ fy }}</div>
            </div>
            <div class="like-btn">
                喜欢
            </div>
        </div>
        <control-bar />
        <grammar-panel />
        <word-card />
    </div>
</template>

<script setup>
import Player from '@/components/VideoPlayer.vue';
import ControlBar from '@/components/PracticeControlBar.vue';
import GrammarPanel from '@/components/GrammarPanel'
const text = 'this is the way we comb our hair comb our hair';
const fy = '这就是我们梳理头发的方式梳理头发的方式梳理头发';
const group = text.split(' ');
</script>
<style scoped>
.blank {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #111215 0%, #0E0E0F 100%);
}

.page-title {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.page-title .percent,
.page-title .time {
    display: inline-flex;
    padding: 8px 20px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 64px;
    background: #1B1D24;
    color: #CCC;
}

.content {
    display: flex;
    max-width: 1045px;
}

.word-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.word-group.hideText .item {
    color: #1B1D24;
    user-select: none;
}

.word-group .item {
    padding: 0px 8px;
    background: #1B1D24;
    color: #FFF;
    text-align: center;
    font-family: PingFang SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.fy {
    color: #999;
    text-align: center;
    font-family: PingFang SC;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 32px;
}

.like-btn {
    width: 24px;
    height: 24px;
    color: #999;
    cursor: pointer;
    margin-left: 20px;
}
</style>