<template>
  <div class="container">
    <div v-if="step != inital" class="subtitle">
      <div class="subtitle-text">Can you clap your hands?</div>
      <template v-if="step === echoPanel">
        <ul class="echoStep">
          <li
            :class="{
              item: true,
              active: echoStep === item.index,
            }"
            v-for="item in echoStepList"
            :key="item.index"
          >
            <div class="bg"></div>
            <div class="text">{{ item.text }}</div>
          </li>
        </ul>
        <div class="explain-text">{{ echoStepList[echoStep].explain }}</div>
      </template>
      <ul
        class="control-group"
        style="
          justify-content: center;
          gap: 16px;
          height: 48px;
          font-size: 24px;
        "
        v-if="step !== echoPanel"
      >
        <li><i class="iconfont">&#xe677;</i></li>
        <li><i class="iconfont">&#xe66f;</i></li>
        <li><i class="iconfont">&#xe671;</i></li>
      </ul>
    </div>
    <div class="control-group">
      <ul class="left">
        <li
          v-if="step !== inital"
          :class="{ item: true, active: step === echoPanel }"
          @click="onEcho"
        >
          <i class="iconfont">&#xe674;</i>自动回声
        </li>
        <li v-if="step === inital" class="item">
          <i class="iconfont">&#xe673;</i>固定
        </li>
        <li class="item"><i class="iconfont">&#xe676;</i>讲解</li>
        <li v-if="step === inital" class="item">
          <i class="iconfont">&#xe670;</i>重复
        </li>
      </ul>
      <ul class="right">
        <li
          :class="{
            item: true,
            disabled: step !== inital,
          }"
          v-if="step != recordPanel"
          @click="onStart"
        >
          <i class="iconfont">&#xe678;</i>
        </li>
        <li class="item" v-if="step === recordPanel">
          <i class="iconfont">&#xe671;</i>
        </li>
        <li class="item" v-if="step === recordPanel">
          <i class="iconfont">&#xe678;</i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useRecorder } from "@/hooks/useRecorder";

const inital = 0; // 初始面板
const startPanel = 1; // 开始面板
const recordPanel = 2; // 录音面板
const playPanel = 3; // 播放面板
const echoPanel = 4; // 回声面板
const listen = 0; // 听
const echo = 1; // 回声
const speak = 2; // 说
const play = 3; // 播放
let step = ref(inital); // 当前面板
let echoStep = ref(listen); // 当前回声步骤
const echoStepList = [
  { index: listen, text: "听", explain: "听音频" },
  { index: echo, text: "回声", explain: "回想并理解句子内容" },
  { index: speak, text: "说", explain: "尝试说出这个句子" },
  { index: play, text: "回放", explain: "聆听并对比你的声音" },
];
const {
  recording,
  recorderPlaying,
  startRecord,
  startReader,
  stopRecord,
  stopReader,
} = useRecorder();
const onStart = () => {
  /** 用户点击回声按钮 */
  step.value = startPanel;
};
let timer;
let sentenceTime = 5 * 1000;
const onEcho = () => {
  step.value = echoPanel;
  echoStep.value = listen;
  timer = setTimeout(() => {
    clearTimeout(timer);
    echoStep.value = echo;
  }, sentenceTime);
};
watch(echoStep, () => {
  if (echoStep.value === echo) {
    timer = setTimeout(() => {
      clearTimeout(timer);
      echoStep.value = speak;
    }, sentenceTime);
  } else if (echoStep.value === speak) {
    timer = setTimeout(() => {
      clearTimeout(timer);
      echoStep.value = play;
      startRecord();
    }, sentenceTime);
  } else if (echoStep.value === play) {
    stopRecord();
    startReader();
  }
});
</script>

<style lang="scss" scoped>
.container {
  border-radius: 20px;
  background: #222325;
  padding: 16px 20px;
  position: fixed;
  left: 50%;
  bottom: 90px;
  width: 400px;
  transform: translate(-50%, -20px);
}
.subtitle {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  margin-bottom: 10px;
  .subtitle-text {
    font-size: 23px;
    color: #fff;
    line-height: 55px;
    text-align: left;
    padding-left: 16px;
  }
}
.control-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #fff;
  .left {
    display: flex;
    align-items: center;
    gap: 32px;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      .iconfont {
        font-size: 24px;
      }
      &.active {
        color: #006ff9;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    .item {
      width: 60px;
      height: 60px;
      background: #006ff9;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &.disabled {
        cursor: default;
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
.echoStep {
  display: flex;
  align-items: flex-start;
  gap: 14px;
  height: 96px;
  justify-content: center;
  padding-top: 12px;
  .item {
    width: 58px;
    height: 44px;
    background: #fff;
    color: #000;
    line-height: 44px;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    transition: width 0.5s;
    .text {
      z-index: 2;
      position: relative;
      color: #262626;
      font-size: 16px;
    }
    &.active {
      width: 116px;
      position: relative;

      .bg {
        position: absolute;
        background: rgba(0, 111, 249, 0.2);
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        box-shadow: -116px 0px 0 0 rgba(0, 111, 249, 1);
        animation: move 5s;
      }
    }
  }
}
.explain-text {
  font-size: 14px;
  color: #5f5f5f;
  position: relative;
  top: -30px;
}
@keyframes move {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>