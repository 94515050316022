<template>
    <div class="control-bar">
        <div class="process-bar">
            <div class="process" :style="{ width: `${currentTime / currentVideoInfo.duration * 100}%` }"></div>
        </div>
        <div class="control-bar-panel">
            <div class="video-info">
                <div class="video-cover"></div>
                <div>
                    <el-popover placement="top-start" effect="dark" :show-arrow="false" trigger="click"
                        popper-class="poppperBox">
                        <template #reference>
                            <div class="mode">
                                <div class="mode-label">模式选择</div>
                                <div class="mode-text">听力模式</div>
                            </div>
                        </template>
                        <ul class="popList">
                            <li v-for="key in ['听力模式', '口语模式', '选词模式', '填空模式', '听写模式']" :key="key"
                                @click="ev => handleChangeRate(ev, key)">
                                <span v-if="playRate === key">选中</span>
                                {{ key }}
                            </li>
                        </ul>
                    </el-popover>
                </div>
            </div>
            <div class="main-control">
                <ul class="operation-group">
                    <li>循环模式</li>
                    <li>上一个</li>
                    <li @click="handleClickPlay" v-if="playState === PLAY_STATE.loading">加载中</li>
                    <li @click="handleClickPlay" v-if="playState === PLAY_STATE.paused || playState === PLAY_STATE.loaded">
                        播放</li>
                    <li @click="handleClickPause" v-if="playState === PLAY_STATE.play">暂停</li>
                    <li @click="handleClickReplay" v-if="playState === PLAY_STATE.ended">重新播放</li>
                    <li>下一个</li>
                    <el-popover placement="top-start" effect="dark" :show-arrow="false" trigger="click"
                        popper-class="poppperBox">
                        <template #reference>
                            <li>倍速</li>
                        </template>
                        <ul class="popList">
                            <li v-for="key in [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 3]" :key="key"
                                @click="ev => handleChangeRate(ev, key)">
                                <span v-if="playRate === key">选中</span>
                                {{ key }}x
                            </li>
                        </ul>
                    </el-popover>
                </ul>
            </div>
            <div class="right-control">
                <ul class="operation-group">
                    <li>AI</li>
                    <li>查看</li>
                    <li>文稿</li>
                    <li>视频</li>
                    <li v-if="!fullscreen" @click="handleFullScreen">全屏</li>
                    <li v-if="fullscreen" @click="handleExitFullScreen">退出全屏</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import PLAY_STATE from '@/contants/playState';
const store = useStore();
const currentVideoInfo = computed(() => store.state.videoPlayer.currentVideoInfo);
const currentTime = computed(() => store.state.videoPlayer.currentTime);
const playState = computed(() => store.state.videoPlayer.playState);
const playRate = computed(() => store.state.videoPlayer.playRate);
const player = computed(() => store.state.videoPlayer.player);
const handleClickPlay = () => player.value.play();
const handleClickPause = () => player.value.pause();
const handleClickReplay = () => player.value.rePlay();
const handleChangeRate = (e, rate) => {
    const props = {
        playbackRate: {
            from: player.value.playbackRate,
            to: rate
        }
    }
    player.value.emitUserAction(e, 'change_rate', { props, pluginName: "playbackrate" })
    player.value.playbackRate = rate
}
let fullscreen = false;
const handleFullScreen = () => {
    document.body.requestFullscreen();
    fullscreen = true;
}
const handleExitFullScreen = () => {
    document.exitFullscreen();
    fullscreen = false;
}
</script>

<style lang="scss" scoped>
.control-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: #000;
}

.process-bar {
    width: 100%;
    height: 2px;
    background: #999;
    border-radius: 8px;
    position: relative;

    .process {
        position: absolute;
        width: 30px;
        height: 100%;
        background: #fff;
    }
}

.control-bar-panel {
    display: flex;
    align-items: center;
    height: 78px;
    justify-content: space-between;
    color: #bbb;
    font-size: 12px;
    padding: 0 20px;
    user-select: none;
    -webkit-user-drag: none;
}

.video-info {
    display: flex;
    grid-gap: 12px;
    align-items: center;

    .video-cover {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid #333;
    }

    .mode {

        .mode-label {
            color: #999;
            font-family: PingFang SC;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .mode-text {
            margin-top: 4px;
            color: #CCC;
            font-family: PingFang SC;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}

.operation-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    grid-gap: 32px;

    li {
        cursor: pointer;

        &:hover {
            color: #fff;
        }
    }
}

.popList {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 8px;

    li {
        text-align: center;
        width: 111px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        color: #fff;
        user-select: none;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            background: #1F222A;

        }
    }
}

.popList+.popList {
    border-top: 1px solid #2F3036;
    padding-top: 8px;
}
</style>
<style lang="scss">
.el-popover.el-popper.is-dark {
    padding: 0;
    background: #181a20;
    border: 1px solid #1f222a;
    border-radius: 20px;
    // width: 127px !important;
    padding: 8px;
    // min-width: 127px;
}

.poppperBox {
    transform: translateX(-50px);
}
</style>