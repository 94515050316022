<template>
  <el-drawer :modal="false" title="单词释义" v-model="sliderShow" :with-header="false"  class="slider-panel">
    <div class="header">
      <i className="iconfont icon-fanhui" @click="sliderShow = false"></i>
      <span>单词释义</span>
    </div>
    <div class="content">
      <div class="card">
        <p class="title">
          <span>{{ props.word }}</span>
          <i :className="`iconfont icon-xihuan ${isLike ? 'icon-xihuan-' :'icon-xihuan'}`"></i>
        </p>
        <!-- <p class="speech">英 /ɪnˈterəɡeɪt/ <i className="iconfont icon-langdudanci"></i></p>
        <p class="speech">美 /ɪnˈterəɡeɪt/ <i className="iconfont icon-langdudanci"></i></p> -->
        <p class="translation">{{ translation }}</p>
      </div>
      <div class="tab-menu">
        <span v-for="(item, idx) in ['例句', '词组', '词性']" :class="`${sliderTab === idx ? 'active' : ''}`"
          @click="sliderTab = idx">{{ item }}</span>
      </div>
      <!-- <div v-if="sliderTab === 0" class="tab-content example">
        <p class="title">视频例句：</p>
        <div class="sentence-card" v-for="item in sentence">
          <p class="original">{{ sentence }}</p>
          <p class="translation">石头剪刀布石头剪刀布一二三跟我玩右手</p>
          <div class="footer">
            <span class="time">02:15</span>
            <i className="iconfont icon-xihuan"></i>
            <i className="iconfont icon-langdudanci"></i>
          </div>
        </div>
      </div> -->
      <div v-if="sliderTab === 0" class="tab-content synonym">
        <div v-html="sentence" />
      </div>
      <div v-if="sliderTab === 1" class="tab-content synonym">
        <div v-html="wordGroup" />
      </div>
      <div v-if="sliderTab === 2" class="tab-content part-of-speech">
        <p v-html="partofSpeech" />
      </div>
    </div>
  </el-drawer>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps, defineExpose } from "vue";
import api from '@/api/video';

const emit = defineEmits(['close']);
const props = defineProps({ word: String });



let translation = ref('暂无数据');
const sentence = ref('暂无数据');
const wordGroup = ref('暂无数据');
const partofSpeech = ref('暂无数据');
const isLike = ref(false);

// TODO 接口
const getExplainInfo = async () => {
  const res = await api.getWordsInfo({ word: props.word });
  const { code, data } = res;
  if (code !== 0 && data.length === 0) return;
  if (data.more_sentence && data.more_sentence[0]) {
    const { chinese_interpretation, etyma_word, word_group, example_sat } = data.more_sentence[0];
    translation.value = chinese_interpretation;
    partofSpeech.value = etyma_word;
    wordGroup.value = word_group;
    sentence.value = example_sat;
    isLike.value = data.is_like;
    console.log(data);
  }
}
const sliderShow = ref(false);
const sliderTab = ref(0);

const changeVisible = () => sliderShow.value = !sliderTab.value;

watch(() => props.word, val => {
  getExplainInfo();
})

defineExpose({
  changeVisible
})

</script>

<style lang="scss">
.slider-panel {
  width: 340px !important;
  background-color: #0E0E0F;

  .header {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 20px;
    border-bottom: 1px solid #2F3036;
    box-sizing: border-box;
    color: #ccc;

    .icon-fanhui {
      color: #999;
      font-size: 20px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
    padding-top: 16px;
    box-sizing: border-box;
  }

  .card {
    padding: 14px 16px 20px;
    background-color: #181A20;
    border-radius: 8px;
    border: 1px solid #ffffff10;
    color: #999;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 20px;
      margin-bottom: 12px;

      .iconfont {
        color: #999;
        font-size: 20px;
      }
    }

    .speech {
      font-size: 14px;
      margin-bottom: 12px;
    }

    .translation {
      font-size: 14px;
      color: #fff;
      line-height: 26px;
      white-space: pre-wrap;

      .pos {
        color: #FBD026;
      }
    }
  }

  .tab-menu {
    display: flex;
    column-gap: 16px;
    color: #999;
    font-size: 14px;
    border-bottom: 1px solid #262627;

    span {
      position: relative;
      line-height: 52px;
      cursor: pointer;

      &.active {
        color: #fff;
      }

      &.active::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        width: 28px;
        height: 2px;
        background-color: #FBD026;
      }
    }
  }

  .tab-content {
    flex: 1;
    overflow-y: scroll;
    color: #999;

    .title {
      line-height: 30px;
      color: #999;
      margin-top: 20px;
      font-size: 12px;
    }

    .sentence-card {
      border-bottom: 1px solid #262627;
      padding: 16px 0 12px 0;
      color: #999;
      font-size: 12px;

      .original {
        color: #fff;
        margin-bottom: 8px;
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        margin-top: 12px;

        .time {
          margin-right: auto;
        }
      }
    }

    &.synonym>div {
      font-size: 12px;
      padding: 16px 0;
      border-bottom: 1px solid #262627;
      white-space: pre-wrap;
      line-height: 2;

      p {
        color: #fff;
        font-size: 14px;
        margin-top: 10px;
        white-space: pre-wrap;
      }

      div p:last-of-type {
        font-size: 12px;
        color: #999;
      }
    }

    &.part-of-speech p {
      margin-top: 16px;
      font-size: 14px;
      white-space: pre-wrap;

      span:last-of-type {
        color: #fff;
        margin-left: 20px;
      }
    }
  }
}
</style>