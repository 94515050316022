<template>
  <el-popover :visible="visible" popper-class="video-control-more-popover"
    popper-style="width: 340px;padding: 0;color: #999999;border: none;background:#181A20;" placement="top-start"
    :show-arrow="false">
    <template #reference>
      <i class="iconfont icon-Union-1" style="font-size: 20px;" @click="changeSettingVisible"></i>
    </template>
    <div class="setting-from" v-click-outside="hideSettingVisible">
      <p class="pop-title">视频设置</p>
      <ul>
        <li class="setting-from-item">
          <div><i class="iconfont icon-xuantingchaci"></i>悬停查词</div>
          <el-switch v-model="state.hoverSearch" @change="(value) => handleSetting('hoverSearch', value)" />
        </li>
        <li class="setting-from-item">
          <div><i class="iconfont icon-dianjifayin"></i>点击发音</div>
          <el-switch v-model="state.clickSpeech" @change="(value) => handleSetting('clickSpeech', value)" />
        </li>
        <li class="setting-from-item">
          <div><i class="iconfont icon-zhinengfenju"></i>智能分句</div>
          <el-switch v-model="state.aiSplit" @change="(value) => handleSetting('aiSplit', value)" />
        </li>
        <li class="setting-from-item">
          <div><i class="iconfont icon-yincangkongzhilan"></i>隐藏控制栏</div>
          <el-switch v-model="state.hiddenControlBar" @change="(value) => handleSetting('hiddenControlBar', value)" />
        </li>
      </ul>
      <ul style="border-top: 1px solid #2F3036;margin: 10px 0;padding-top: 10px;">
        <li class="setting-from-item" @click="handleCollectText"><i class="iconfont icon-shengciyangshi"></i>生词样式<i
            class="iconfont icon-gengduo"></i></li>
        <li class="setting-from-item" @click="handleSubtitleText"><i class="iconfont icon-zimuyangshi"></i>字幕样式<i
            class="iconfont icon-gengduo"></i>
        </li>
        <li class="setting-from-item"><i class="iconfont icon-zimuyuyan"></i>字幕语言<i class="iconfont icon-gengduo"></i>
        </li>
      </ul>
      <ul style="border-top: 1px solid #2F3036;">
        <li class="quick-keyboard setting-from-item" @click="handleKeyBoard"><i
            class="iconfont icon-jianpankuaijiejian"></i>键盘快捷键<i class="iconfont icon-gengduo"></i></li>
      </ul>
    </div>
  </el-popover>
  <Teleport to="body">
    <QuickKeyBoard @changeVisible="changeSettingVisible" />
  </Teleport>
  <Teleport to="body">
    <SubtitleTextSetting @changeVisible="changeSettingVisible" />
  </Teleport>
  <Teleport to="body">
    <CollectTextSetting @changeVisible="changeSettingVisible" />
  </Teleport>
</template>
<script setup>
import { reactive, computed } from 'vue';
import { useStore } from 'vuex';
import { ClickOutside as vClickOutside } from 'element-plus'
import QuickKeyBoard from '@/components/QuickKeyBoard';
import SubtitleTextSetting from '@/components/SubtitleTextSetting';
import CollectTextSetting from '@/components/CollectTextSetting.vue';
const store = useStore();
const visible = computed(() => store.state.videoSetting.visible);
const state = reactive({
  ...store.state.videoSetting.settings,
})

const handleSetting = (key, value) => {
  store.commit('videoSetting/updateSetting', { ...store.state.videoSetting.settings, [key]: value });
}

const handleKeyBoard = () => {
  store.commit('videoSetting/updateKeyboard', true);
  store.commit('videoSetting/updateSettingVisible', false);
}
const handleSubtitleText = () => {
  store.commit('videoSetting/updateSubtitleTextPanel', true);
  store.commit('videoSetting/updateSettingVisible', false);
}
const handleCollectText = () => {
  store.commit('videoSetting/updateCollectTextPanel', true);
  store.commit('videoSetting/updateSettingVisible', false);
}
const changeSettingVisible = () => {
  store.commit('videoSetting/updateSettingVisible', !visible.value);
}
const hideSettingVisible = () => {
  store.commit('videoSetting/updateSettingVisible', false);
}
</script>
<style lang="scss">
.video-control-more-popover {
  border: none;

  .setting-from {
    .pop-title {
      padding: 0 20px;
      height: 54px;
      line-height: 54px;
      border-bottom: 1px solid #2F3036;
      font-size: 16px;
      box-sizing: border-box;
      margin-bottom: 10px;
    }

    &-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        color: #fff;
        background: #ffffff10;
      }

      .el-switch {
        margin-left: auto;
        --el-switch-off-color: #1F222A;

        &.is-checked {
          --el-switch-on-color: #1F222A;
          --el-color-white: #006FF9;
        }
      }
    }

    .quick-keyboard {
      margin: 10px 0;
      line-height: 40px;
    }

    li {
      display: flex;
      padding: 0 20px;
      line-height: 40px;
    }

    .iconfont {
      font-size: 20px;
      margin-right: 12px;

      &.icon-gengduo {
        margin-left: auto;
      }
    }
  }
}
</style>