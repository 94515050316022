import { createRouter, createWebHashHistory } from 'vue-router'

const routerHistory = createWebHashHistory()

import Vpage from '../view/page.vue'
import Vlogin from '../view/login.vue'
import VVideo from '../view/video-theater.vue'
import VideoListen from '../view/video-listen.vue'
import VideoOral from '../view/video-oral.vue'
import VideoBlank from '../view/video-blank.vue'
import VideoList from '../view/video-list.vue';
import tokenLogin from "../view/tokenLogin.vue";
import VMind from '../view/mind.vue'

import "../assets/font/iconfont.css"

// 定义路由
const routes = [
    {
        path: '/',
        name: 'page',
        component: Vpage
    },
    {
        path: '/mind/:word',
        name: 'mind',
        component: VMind,
        meta: {
            layout: true
        }
    },
    {
        path: '/ai-video',
        name: 'ai-video',
        component: VideoList,
        meta: {
            layout: true
        }
    },
    {
        path: '/ai-video/detail',
        name: 'video-detail',
        component: VVideo,
        beforeEnter: (to, from, next) => {
            if (to.query.video_id) localStorage.setItem('curVideoId', to.query.video_id);
            if (!to.query.video_id) to.query.video_id = localStorage.getItem('curVideoId');
            next()
        },
    },
    {
        path: '/video/listen',
        name: 'video/listen',
        component: VideoListen
    },
    {
        path: '/video/oral',
        name: 'video/oral',
        component: VideoOral
    },
    {
        path: '/video/blank',
        name: 'video/blank',
        component: VideoBlank
    },
    {
        path: '/login',
        name: 'Vlogin',
        component: Vlogin
    },
    {
        path: "/chatninja/:token",
        name: "tokenLogin",
        component: tokenLogin,
        meta: { title: "ChatNinja 你的AI忍者助手" },
    },
]

// 创建路由器
const router = createRouter({
    history: routerHistory,
    routes: routes
})

export default router