<template>
    <div class="panel">
        <div class="header">
            AI语法分析
        </div>
        <div class="body">
            <p>翻译：</p>
            <p>石头剪刀布，石头剪刀布，一二三，和我
                玩，用右手。</p>
            <p>翻译：</p>
            <p>石头剪刀布，石头剪刀布，一二三，和我
                玩，用右手。</p>
            <p>拆解句子结构：</p>
            <p>石头剪刀布，石头剪刀布，一二三，和我
                玩，用右手。</p>
            <p>翻译：</p>
            <p>这是一个命令句，由两个主语短语和一个谓语动词组成。第一个主语短语是
                “rock scissors paper”,表示石头、剪刀、布三种手势；第二个主语短语是"one two three”,表示数数的顺序：谓语动词是"ply”,表示玩游戏：
                后面跟着介词短语"with me”,表示和我一起玩；最后是形容词短语'right hand',表示用右
                手。</p>
            <p>翻译：</p>
            <p>这是一个命令句，由两个主语短语和一个谓语动词组成。第一个主语短语是
                “rock scissors paper”,表示石头、剪刀、布三种手势；第二个主语短语是"one two three”,表示数数的顺序：谓语动词是"ply”,表示玩游戏：
                后面跟着介词短语"with me”,表示和我一起玩；最后是形容词短语'right hand',表示用右
                手。</p>
        </div>
        <div class="footer">
            Power by OpenAl GPT
        </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
.panel {
    display: flex;
    width: 340px;
    height: 70vh;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #181A20;
    position: absolute;
    right: 20px;
    overflow: hidden;
}

.panel .header {
    color: #CCC;
    font-family: PingFang SC;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-bottom: 1px solid #2F3036;
    width: 100%;
    padding-bottom: 16px;
    padding-left: 20px;
}

.panel .body {
    color: #999;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0 20px;
    overflow: overlay;
}

.panel .body p {
    margin-bottom: 10px;
}

.panel .footer {
    color: #666;
    font-family: PingFang SC;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    user-select: none;
    width: 100%;
}
</style>