<template>
  <div class="practice-body">
    <div class="counter"><span class="cur">26</span> / <span>635</span></div>
    <div class="practice-content">
      <div class="practice-content-main">
        <div style="display: flex">
          <ul class="words-list">
            <!-- 听力模式 -->
            <template v-if="practiceMode === 1">
              <li
                class="words-list-item"
                v-for="(word, idx) in sentence"
                :key="idx"
                @click="showPop(idx)"
              >
                <span
                  v-if="currentWordIdx !== idx"
                  :style="`visibility: ${wordVisible ? 'visible' : 'hidden'}`"
                  >{{ word }}</span
                >
                <el-popover
                  v-else
                  :visible="true"
                  placement="top"
                  trigger="hover"
                  popper-style="min-width: 80px;width: 80px;padding: 8px 12px; box-sizing: border-box;background: #006FF9; border: none;"
                >
                  <template #reference>
                    <span>{{ word }}</span>
                  </template>
                  <div
                    class="practice-word-popover"
                    v-click-outside="onClickOutside"
                  >
                    <div>
                      <p>解释</p>
                      <p>释义</p>
                    </div>
                    <div class="collected-wrapper" @click="onCollectWord(idx)">
                      <i
                        v-if="!isCollectd(idx)"
                        class="iconfont icon-xihuan"
                      ></i>
                      <i
                        v-else
                        class="iconfont icon-xihuan-"
                        style="color: #ef4242"
                      ></i>
                    </div>
                  </div>
                </el-popover>
              </li>
            </template>
            <!-- 口语模式 -->
            <template v-if="practiceMode === 2">
              <li
                class="words-list-item speeking"
                v-for="(word, idx) in sentence"
                :key="idx"
                @click="handleParaphrase(word)"
              >
                {{ word }}
              </li>
            </template>
            <!-- 选词模式 -->
            <template v-if="practiceMode === 3">
              <li
                class="words-list-item-2"
                v-for="(data, idx) in choosedWordData"
                :key="idx"
              >
                {{ data.curWord }}
              </li>
            </template>
            <!-- 填空模式 -->
            <template v-if="practiceMode === 4"></template>
            <!-- 听写模式 -->
            <template v-if="practiceMode === 5"></template>
            <!-- 跟读模式 -->
            <template v-if="practiceMode === 6">
              <Echo></Echo>
            </template>
          </ul>
          <div class="sentence-collected-wrapper">
            <i
              v-if="!sentenceCollected"
              class="iconfont icon-xihuan"
              @click="sentenceCollected = true"
            ></i>
            <i
              v-else
              class="iconfont icon-xihuan-"
              style="color: #ef4242"
              @click="sentenceCollected = false"
            ></i>
          </div>
        </div>
        <p class="paraphrase" v-if="paraphraseShow">{{ paraphrase }}</p>
        <template v-if="practiceMode === 2">
          <div class="oral-practice">
            <div v-if="!recording" class="record-box" @click="startRecord">
              <i class="iconfont icon-kouyu"></i>
            </div>
            <div v-else class="record-box" @click="stopRecord">
              <i class="iconfont icon-zanting1"></i>
            </div>
            <div class="record-play-box" @click="playRecorder">
              <i class="iconfont icon-langdudanci"></i>
            </div>
          </div>
          <div v-if="recording" style="margin-top: 40px">
            <img src="../assets/video/wave.webp" alt="" />
            <p style="font-size: 12px; margin-top: 10px">评测中</p>
          </div>
        </template>
        <ul class="choice-words" v-if="practiceMode === 3">
          <li
            :class="`choice-words-item ${data.error ? 'error' : ''}`"
            v-for="(data, idx) in choosedWordData"
            @click="chooseWord(idx)"
            :key="idx"
          >
            {{ data.confusedWord }}
          </li>
        </ul>
      </div>
    </div>
    <SliderPanel
      ref="sliderPanel"
      :word="sliderCurrentWord"
      @close="sliderShow = false"
    ></SliderPanel>
  </div>
</template>

<script setup>
import { ref } from "vue";
import emitter from "@/utils/eventBus";
import { ClickOutside as vClickOutside } from "element-plus";
import Recorder from "js-audio-recorder";
import { shuffle } from "lodash";
import SliderPanel from "@/components/SliderPanel";
import Echo from "@/components/Echo.vue";
const recorderConfig = {
  sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
  sampleRate: 48000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
  numChannels: 1, // 声道，支持 1 或 2， 默认是1
  // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
};

const sentence =
  "just as a single word can change the meaning of a sentence a single sentence can change the meaning of a paragraph".split(
    " "
  );
const confusedSentence = shuffle(sentence);
const paraphrase =
  "正如一个单词可以改变一句话的意思，一句话可以改变一段话的意思。";

const wordVisible = ref(false);
const currentWordIdx = ref(null);
const collectWordIdxList = ref([]);
const sentenceCollected = ref(false);
const paraphraseShow = ref(false);
const practiceMode = ref(1);

emitter.on(
  "practice-player-visible",
  (visible) => (wordVisible.value = visible)
);
emitter.on(
  "practice-player-translate",
  (translate) => (paraphraseShow.value = translate)
);
emitter.on("practice-mode-change", (mode) => {
  practiceMode.value = mode.value;
  if (recorder) destroyRecord();
});

const showPop = (idx) => (currentWordIdx.value = idx);
const onClickOutside = () => (currentWordIdx.value = null);
const isCollectd = (idx) => collectWordIdxList.value.includes(idx);
const onCollectWord = (curIdx) => {
  let idx = collectWordIdxList.value.findIndex((item) => item === curIdx);
  if (idx > -1) {
    collectWordIdxList.value.splice(idx, 1);
  } else {
    collectWordIdxList.value.push(curIdx);
  }
};
// 口语模式--录音
const recording = ref(false);
let recorder = null;
const startRecord = () => {
  recording.value = true;
  if (!recorder) {
    recorder = new Recorder(recorderConfig);
  } else {
    recorder.stop();
  }

  recorder.start().then(
    () => {
      console.log("开始录音");
    },
    (error) => {
      console.log(`异常了,${error.name}:${error.message}`);
    }
  );
  recorder.onplayend = () => {
    console.log("%c回调监听，音频已经完成播放", "color: #2196f3");
    recorderPlaying.value = false;
  };
};
const stopRecord = () => {
  recording.value = false;
  recorder && recorder.stop();
  console.log("结束录音");
};
const recorderPlaying = ref(false);
const playRecorder = () => {
  if (!recorderPlaying.value) {
    console.log("播放录音");
    recorder.play();
  } else {
    console.log("暂停录音");
    recorder.stopPlay();
  }
  recorderPlaying.value = !recorderPlaying.value;
};
const destroyRecord = () => {
  recorder.destroy().then(() => {
    console.log("销毁实例");
    recorder = null;
  });
};

// 选词模式
const choosedWordData = ref([]);
choosedWordData.value = sentence.map((item, idx) => ({
  originWord: item,
  originIdx: null,
  confusedWord: confusedSentence[idx],
  curWord: null,
  curWordIdx: null,
  error: false,
}));

const chooseWord = (idx) => {
  let isDisabled = choosedWordData.value.some(
    (item) => item.curWordIdx === idx
  );
  if (isDisabled) return;
  let emptyIdx = choosedWordData.value.findIndex(
    (item) => item.curWordIdx === null
  );
  Object.assign(choosedWordData.value[emptyIdx], {
    curWord: confusedSentence[idx],
    curWordIdx: idx,
  });
  // 比对原句 取出错误的
  const { curWord, originWord } = choosedWordData.value[emptyIdx];
  if (curWord !== originWord) choosedWordData.value[emptyIdx].error = true;
};

// 单词释义面板
const sliderPanel = ref(null);
const sliderCurrentWord = ref(null);
const handleParaphrase = (word) => {
  sliderPanel.value.changeVisible();
  sliderCurrentWord.value = word;
};
</script>

<style lang="scss" scoped>
.practice-body {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  .counter {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    line-height: 38px;
    background-color: #1b1d24;
    border-radius: 64px;
    color: #ccc;
    padding: 0 20px;
    font-size: 16px;
    .cur {
      color: #fff;
      font-weight: 600;
    }
  }
  .practice-content {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    &-main {
      width: 70%;
      text-align: center;
      color: #999;
      padding-top: 58px;
      box-sizing: border-box;
      font-size: 24px;
      .words-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        row-gap: 12px;
        &-item {
          line-height: 34px;
          padding: 0 4px;
          color: #fff;
          margin-right: 4px;
          background-color: #1b1d24;
          cursor: pointer;
          &:hover span {
            visibility: visible !important;
          }
          &.speeking {
            background-color: transparent;
            &:hover {
              background-color: #1b1d24;
            }
          }
        }
        &-item-2 {
          width: 120px;
          height: 34px;
          line-height: 34px;
          border-bottom: 1px solid #fff;
          margin-right: 12px;
          color: #65fec7;
          &:hover {
            visibility: visible !important;
          }
        }
      }
      .choice-words {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 8px;
        margin-top: 32px;
        &-item {
          margin-right: 8px;
          padding: 0 12px;
          line-height: 58px;
          border: 1px solid #ffffff10;
          border-radius: 8px;
          cursor: pointer;
          &:hover {
            border-color: #fff;
          }
          &.error {
            border-color: #ef424230;
          }
        }
      }
      .sentence-collected-wrapper {
        .iconfont {
          font-size: 24px;
          cursor: pointer;
        }
      }
      .paraphrase {
        margin-top: 28px;
      }
    }
  }
}

.page-title {
  color: #fff;
  font-family: PingFang SC;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.group {
  margin: 0;
  padding: 0;
  display: flex;
  grid-gap: 24px;
  align-items: center;
  margin-top: 68px;
  margin-bottom: 128px;
  list-style: none;
}

.item {
  width: 140px;
  height: 140px;
  padding: 27px 38px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: linear-gradient(180deg, #1b1d24 0%, #181a20 100%);
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .iconfont {
    color: #fff;
    font-size: 24px;
  }

  p {
    margin: 0;
    color: #ccc;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
  }

  p + p {
    font-size: 12px;
    margin-top: 4px;
  }
}

.btn {
  display: inline-flex;
  padding: 8px 112px;
  justify-content: center;
  align-items: center;
  border-radius: 16.5px;
  background: #006ff9;
  color: #fff;
  font-family: PingFang SC;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.oral-practice {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  gap: 60px;
  .record-box,
  .record-play-box {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #fbd026;
    box-sizing: border-box;
    cursor: pointer;
    i {
      line-height: 46px;
      font-size: 24px;
    }
  }
  .record-box {
    background-color: #fbd026;
    i {
      color: #0e0e0f;
    }
  }
}
</style>
<style lang="scss">
.practice-word-popover {
  font-size: 24px;
  color: #fff;
  text-align: center;
  line-height: 38px;
  .collected-wrapper {
    width: 100%;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    background-color: #ffffff24;
    margin-top: 4px;
  }
  .iconfont {
    font-size: 24px;
    cursor: pointer;
  }
}
.el-popper__arrow::before {
  border: none !important;
  background: #006ff9 !important;
}
</style>