<template>
  <div v-clickOutside="handleHide" class="quick-key-board" v-if="keyBoard">
    <div class="header">
      <i className="back-icon iconfont icon-fanhui" @click="handleClose"></i>
      <span>快捷键</span>
    </div>
    <div class="content">
      <ul>
        <li class="shortcuts bottom-border">
          <span class="name">开启Chat Ninja</span>
          <div style="display: flex;align-items: center;"><span class="icon">⌘</span><span class="icon" style="margin-left: 4px;">E</span><span style="margin-left: 20px;">更改</span><i class="iconfont icon-gengduo" style="font-size: 20px;transform: translateX(60pxpx);"></i></div>
        </li>
        <li class="split-box"></li>
        <li class="shortcuts"><span class="name">退出</span><span class="icon">ESC</span></li>
        <li class="shortcuts"><span class="name">播放/暂停</span><span class="icon">Space/k</span></li>
        <li class="shortcuts"><span class="name">下一句</span><span class="icon">▶️</span></li>
        <li class="shortcuts"><span class="name">加速播放</span><span class="icon">S</span></li>
        <li class="shortcuts"><span class="name">减速播放</span><span class="icon">D</span></li>
        <li class="shortcuts"><span class="name">上一句</span><span class="icon">◀️</span></li>
        <li class="shortcuts"><span class="name">循环当前句</span><span class="icon">R</span></li>
        <li class="shortcuts"><span class="name">退后 2 秒</span><span class="icon">▲</span></li>
        <li class="shortcuts"><span class="name">切换剧场/阅读模式</span><span class="icon">M</span></li>
        <li class="shortcuts"><span class="name">练习模式</span><span class="icon">P</span></li>
        <li class="shortcuts"><span class="name">查看快捷键</span><span class="icon">？</span></li>
        <li class="shortcuts"><span class="name">打开本页收藏</span><span class="icon">C</span></li>
        <li class="shortcuts">
          <span class="name">开启/关闭视频全屏</span>
          <div style="display: flex;align-items: center;"><span class="icon padding2">⌥</span> + <span class="icon">W</span></div>
        </li>
        <li class="shortcuts bottom-border">
          <span class="name">开启/关闭视频全屏</span>
          <div style="display: flex;align-items: center;"><span class="icon padding2">⌥</span> + <span class="icon">F</span></div>
        </li>
        <li class="split-box"></li>
        <li class="shortcuts"><span class="name">智能分句</span><span class="icon">I</span></li>
        <li class="shortcuts"><span class="name">放大字体</span><span class="icon">=</span></li>
        <li class="shortcuts"><span class="name">缩小字体</span><span>-</span></li>
        <li class="shortcuts"><span class="name">增大字幕宽度</span><span class="icon">]</span></li>
        <li class="shortcuts bottom-border"><span class="name">减小字幕宽度</span><span class="icon">[</span></li>
        <li class="practice-mode-title">练习模式</li>
        <li class="shortcuts">
          <span class="name">开启/关闭键盘声音</span>
          <div style="display: flex;align-items: center;"><span class="icon">⌥</span> + <span class="icon">X</span></div>
        </li>
        <li class="shortcuts">
          <span class="name">显示/隐藏视频</span>
          <div style="display: flex;align-items: center;"><span class="icon">⌥</span> + <span class="icon">V</span></div>
        </li>
        <li class="shortcuts">
          <span class="name">显示/隐藏翻译</span>
          <div style="display: flex;align-items: center;"><span class="icon">⌥</span> + <span class="icon">Z</span></div>
        </li>
        <li class="shortcuts">
          <span class="name">显示/隐藏提示</span>
          <div style="display: flex;align-items: center;"><span class="icon">⌥</span> + <span class="icon">C</span></div>
        </li>
        <li class="shortcuts bottom-border">
          <span class="name">显示/隐藏单词</span>
          <div style="display: flex;align-items: center;"><span>⌥</span> + <span>A</span></div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup>
import { computed, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { ClickOutside as vClickOutside } from 'element-plus'

const store = useStore();
const emit = defineEmits(['changeVisible']);

const keyBoard = computed(() => store.state.videoSetting.keyboard);

const handleClose = (parentShow=true) => {
  store.commit('videoSetting/updateKeyboard', false);
  emit('changeVisible', parentShow);
}
const handleHide = (parentShow=true) => {
  store.commit('videoSetting/updateKeyboard', false);
}

</script>

<style lang="scss">
.quick-key-board {
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 100px;
  width: 340px;
  background: #181A20;
  border-radius: 4px;
  color: #999;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  .header {
    line-height: 60px;
    color: #ccc;
    font-size: 20px;
    font-weight: 500;
    padding: 0 20px;
    border-bottom: 1px solid #2F3036;
    box-sizing: border-box;
    .back-icon {
      font-size: 20px;
      margin-right: 12px;
      cursor: pointer;
    }
  }
  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 16px 20px;
    .shortcuts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #2F3036;
      border-bottom: none;
      height: 36px;
      box-sizing: border-box;
      padding: 0 12px;
      &.bottom-border {
        border-bottom: 1px solid #2F3036;
      }
      .icon {
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        border: 1px solid #2F3036;
        box-sizing: border-box;
        &.padding2 {
          padding: 0 2px;
        }
      }
    }
    .split-box {
      height: 16px;
    }
    .practice-mode-title {
      line-height: 60px;
      font-weight: 500;
      color: #666
    }
  }
}
</style>