<template>
  <Layout v-if="$route.meta.layout">
    <router-view/>
  </Layout>
  <router-view v-else />
</template>

<script>
 import api from './api/api';
 import Layout from './layout/index.vue'
export default {
  name: 'App',
  components: {
    Layout
  },
  
  data(){
    return {
   
    }
  },  
  setup(){
    // let data = {}
    // data.username = '用户名'
    // data.password = '密码'
    // api.login(data).then((res)=>{
    //   if(res.code == 0){
    //     localStorage.setItem('vb-token',res.data.token)
    //   }
     
    //   console.log(res)
    // });
  },
  created() {
   
  },
  mounted() {
    
  },
  methods:{
   
  }
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
ul, li {
  list-style: none;
}
// img {
//   display: block;
// }
</style>
