<template>
    <div class="oral">
        <player :width="335" :height="184" />
        <div class="lyric">
            <ul>
                <li v-for="item in lyric" :key="item.text" class="item" @click="handleClickItem(item)">
                    <div class="play-btn">播放</div>
                    <div class="text">
                        <p class="english">{{ item.text }}</p>
                        <p class="chinese">{{ item.fy }}</p>
                    </div>
                    <div class="like-btn" @click.stop="handleClickLike(item)">喜欢</div>
                </li>
            </ul>
        </div>
        <control-bar />
    </div>
</template>

<script setup>
import lyric from '../mock/lyric'
import ControlBar from '@/components/VideoControlBar';
import Player from '../components/VideoPlayer.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
const store = useStore();
const myPlayer = computed(() => store.state.videoPlayer.player);
const handleClickItem = (item) => {
    const _player = myPlayer.value;
    _player.currentTime = item.startTime;
};
const handleClickLike = () => {

};
</script>

<style lang="scss">
.oral {
    background: #000;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    grid-gap: 20px;

    .lyric {
        width: 690px;
        height: calc(100vh - 120px);
        background: #1F1F25;
        overflow: overlay;
        padding: 28px 12px;

        .item {
            display: flex;
            margin-bottom: 24px;

            .play-btn {
                width: 24px;
                height: 24px;
                color: #999;
                opacity: 0;
            }

            .text {
                flex: 1;

                .english {
                    font-size: 24px;
                    color: #fff;
                }

                .chinese {
                    font-size: 24px;
                    color: #999;
                }
            }

            .like-btn {
                width: 24px;
                height: 24px;
                color: #999;
                opacity: 0;
            }
        }

        .item:hover {
            cursor: pointer;

            .play-btn {
                opacity: 1;
            }

            .english {
                color: #FBD026;
            }

            .like-btn {
                opacity: 1;
            }
        }
    }
}
</style>
<style>
ul {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

::-webkit-scrollbar-corner {
    background-color: transparent;
}
</style>