<template>
  <div :id="videoId"></div>
</template>

<script setup>
import PLAY_STATE, { ModeType } from "../contants/playState";
import Player, { Events } from "xgplayer";
import "xgplayer/dist/index.min.css";
import { computed, onMounted, defineProps } from "vue";
import { useStore } from "vuex";
import emitter from "@/utils/eventBus";

const store = useStore();
const currentVideoInfo = computed(
  () => store.state.videoPlayer.currentVideoInfo
);
const CurSubTitle = computed(() => store.state.subtitles.curSubtitle);
const videoId = "mse";
const props = defineProps({
  width: String,
});
const modeType = computed(() => store.state.videoPlayer.modeType);
onMounted(() => {
  const container = document.getElementById(videoId);
  if (!container) return;
  const player = new Player({
    id: videoId,
    url: currentVideoInfo.value.video_url,
    poster: currentVideoInfo.value.video_img,
    fitVideoSize: "auto",
    defaultPlaybackRate: 1,
    controls: false,
  });
  if (currentVideoInfo.value.startTime) {
    const timer = setTimeout(() => {
      player.currentTime = currentVideoInfo.value.startTime;
      clearTimeout(timer);
    }, 0);
  }
  store.commit("videoPlayer/setPlayer", player);
  player.on(Events.LOAD_START, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.loading)
  );
  player.on(Events.LOADED_DATA, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.loaded)
  );
  player.on(Events.PLAY, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.play)
  );
  player.on(Events.PAUSE, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.paused)
  );
  player.on(Events.ENDED, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.ended)
  );
  player.on(Events.RATE_CHANGE, () =>
    store.commit("videoPlayer/changePlayRate", player.playbackRate)
  );
  player.on(Events.ERROR, (error) => {
    player.pause();
    console.log(error);
  });
  player.on(Events.TIME_UPDATE, () => {
    emitter.emit(Events.TIME_UPDATE, player.currentTime);
    store.commit("videoPlayer/changeCurrentTime", player.currentTime);
    if (modeType.value === ModeType.single && CurSubTitle.value) {
      if (player.currentTime > CurSubTitle.value.endTime) {
        player.pause();
        player.currentTime = CurSubTitle.value.startTime;
        const timer = setTimeout(() => {
          player.play();
          clearTimeout(timer);
        }, 0);
      }
    }
  });
  player.on(Events.PROGRESS, () => {
    store.commit("videoPlayer/changeBufferTime", player.getBufferedRange()[1]);
  });
});
</script>