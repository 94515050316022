<template>
	<div class="practice-modal">
		<h2 class="page-title">了解 Chat 练习模式</h2>
		<ul class="group">
			<li class="item">
				<div><i class="iconfont icon-tingli"></i></div>
				<p>听力模式</p>
				<p>精听、盲听</p>
			</li>
			<li class="item">
				<div><i class="iconfont icon-kouyu"></i></div>
				<p>口语模式</p>
				<p>影子跟读复数对比</p>
			</li>
			<li class="item">
				<div><i class="iconfont icon-xuanci"></i></div>
				<p>选词模式</p>
				<p>选择单词填空</p>
			</li>
			<li class="item">
				<div><i class="iconfont icon-shengciyangshi"></i></div>
				<p>填空模式</p>
				<p>键盘输入生词</p>
			</li>
			<li class="item">
				<div><i class="iconfont icon-tingxie"></i></div>
				<p>听写模式</p>
				<p>默写完整句子</p>
			</li>
		</ul>
		<div class="btn" @click="closeModal">知道了</div>
	</div>
</template>
<script setup>
import { defineEmits } from 'vue';
	const emit = defineEmits(['close'])
  const closeModal = () => emit('close');
</script>
<style lang="scss" scoped>
.practice-modal {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	background: #0f1013;
  text-align: center;
	z-index: 99;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.page-title {
	color: #fff;
	font-family: PingFang SC;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.group {
	margin: 0;
	padding: 0;
	display: flex;
	grid-gap: 24px;
	align-items: center;
	margin-top: 68px;
	margin-bottom: 128px;
	list-style: none;
}

.item {
	width: 140px;
	height: 140px;
	padding: 27px 38px;
	border-radius: 20px;
	border: 1px solid rgba(255, 255, 255, 0.08);
	background: linear-gradient(180deg, #1b1d24 0%, #181a20 100%);
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	.iconfont {
		color: #fff;
		font-size: 24px;
	}

	p {
		margin: 0;
		color: #ccc;
		font-family: PingFang SC;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		margin-top: 24px;
	}

	p + p {
		font-size: 12px;
		margin-top: 4px;
	}
}

.btn {
	display: inline-flex;
	padding: 8px 112px;
	justify-content: center;
	align-items: center;
	border-radius: 16.5px;
	background: #006ff9;
	color: #fff;
	font-family: PingFang SC;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
  cursor: pointer;
}
</style>
